<template>
  <div class="ViewFnAssess">
    <h4>*评估指标</h4>
    <div class="zb">
      <div class="zb-box">
        <div>
          <p>
            1.远距隐斜:
            <span v-if="postData.S_OP_Json.S_OP_VF_HP_Far === ''">--</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_BIBOFar=='BI'" :class="(postData.S_OP_Json.S_OP_VF_HP_Far > 2 || postData.S_OP_Json.S_OP_VF_HP_Far < 0)?'cwcolor':''">{{(postData.S_OP_Json.S_OP_VF_HP_Far > 2 || postData.S_OP_Json.S_OP_VF_HP_Far < 0)?'外隐斜':'正常'}}</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_BIBOFar=='BO'" class="cwcolor">{{'内隐斜'}}</span>
          </p>
          <p>
            3.调节幅度:
            <span v-if="postData.S_OP_Json.S_OP_VF_AMP_OU1 === ''">--</span>
            <span class="cwcolor" v-if="ampNum !== '' && postData.S_OP_Json.S_OP_VF_AMP_OU1 !== ''">{{postData.S_OP_Json.S_OP_VF_AMP_OU1 < ampNum?'低':'正常'}}</span>
          </p>
          <p>
            5.正相对调节:
            <span v-if="postData.S_OP_Json.S_OP_VF_PRA_OU === ''">--</span>
            <span class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_PRA_OU!==''">{{postData.S_OP_Json.S_OP_VF_PRA_OU < -2.5?'低':'正常'}}</span>
          </p>
          <p>
            7.调节灵活度(双眼):--
          </p>
        </div>
        <div class="zb-right">
          <p>
            2.近距隐斜:
            <span v-if="postData.S_OP_Json.S_OP_VF_HP_Near === ''">--</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_BIBONear=='BI'" :class="postData.S_OP_Json.S_OP_VF_BIBONear !=='BI'?'cwcolor':''">{{(postData.S_OP_Json.S_OP_VF_HP_Near < 6 && postData.S_OP_Json.S_OP_VF_HP_Near > 0)?'正常':'外隐斜'}}</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_BIBONear=='BO'" class="cwcolor">{{'内隐斜'}}</span>
          </p>
          <p>4.调节反应:--</p>
          <p>
            6.负相对调节:
            <span v-if="postData.S_OP_Json.S_OP_VF_NRA_OU === ''">--</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_NRA_OU !== ''">
              <span class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_NRA_OU < 2.25">{{postData.S_OP_Json.S_OP_VF_NRA_OU < 2.25?'低':''}}</span>
              <span class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_NRA_OU > 2.5">{{postData.S_OP_Json.S_OP_VF_NRA_OU > 2.5?'高':''}}</span>
              <span v-if="postData.S_OP_Json.S_OP_VF_NRA_OU >= 2.25 && postData.S_OP_Json.S_OP_VF_NRA_OU <= 2.5">{{(postData.S_OP_Json.S_OP_VF_NRA_OU >= 2.25 && postData.S_OP_Json.S_OP_VF_NRA_OU <= 2.5) ?'正常':''}}</span>
            </span>
          </p>
          <p>
            8.梯度性AC/A比率:
            <span v-if="postData.S_OP_Json.S_OP_VF_ACAStep === ''">--</span>
            <span v-if="postData.S_OP_Json.S_OP_VF_ACAStep !== ''">
              <span class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_ACAStep < 3">{{postData.S_OP_Json.S_OP_VF_ACAStep < 3?'低':''}}</span>
              <span class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_ACAStep > 5">{{postData.S_OP_Json.S_OP_VF_ACAStep > 5?'高':''}}</span>
              <span v-if="postData.S_OP_Json.S_OP_VF_ACAStep >= 3 && postData.S_OP_Json.S_OP_VF_ACAStep <= 5">{{(postData.S_OP_Json.S_OP_VF_ACAStep >= 3 && postData.S_OP_Json.S_OP_VF_ACAStep <= 5) ?'正常':''}}</span>
            </span>
          </p>
        </div>
      </div>
      <p>9.集合近点(NPC):--</p>
      <div class="zb-box">
        <div>
          <p>10.远距正融像性集合:<span :class="Ybo.indexOf('--')!==-1||Ybo.indexOf('正常')!==-1?'':'cwcolor'">{{Ybo}}</span></p>
          <p>12.近距正融像性集合:<span :class="Jbo.indexOf('--')!==-1||Jbo.indexOf('正常')!==-1?'':'cwcolor'">{{Jbo}}</span></p>
          <p>14.远距正相对集合:<span :class="Yzxd.indexOf('--')!==-1||Yzxd.indexOf('正常')!==-1?'':'cwcolor'">{{Yzxd}}</span></p>
          <p>16.近距正相对集合:<span :class="Jzxd.indexOf('--')!==-1||Jzxd.indexOf('正常')!==-1?'':'cwcolor'">{{Jzxd}}</span></p>
        </div>
        <div class="zb-right">
          <p>11.远距负融像性集合:<span :class="Ybi.indexOf('--')!==-1||Ybi.indexOf('正常')!==-1?'':'cwcolor'">{{Ybi}}</span></p>
          <p>13.近距负融像性集合:<span :class="Jbi.indexOf('--')!==-1||Jbi.indexOf('正常')!==-1?'':'cwcolor'">{{Jbi}}</span></p>
          <p>14.远距负相对集合:<span :class="Yfxd.indexOf('--')!==-1||Yfxd.indexOf('正常')!==-1?'':'cwcolor'">{{Yfxd}}</span></p>
          <p>16.近距负相对集合:<span :class="Jfxd.indexOf('--')!==-1||Jfxd.indexOf('正常')!==-1?'':'cwcolor'">{{Jfxd}}</span></p>
        </div>
      </div>
    </div>
    <div style="padding: 5px 0;">
      <div>
        <span>系统分析: </span>
        <span v-if="xtfx === ''" style="color: #ff0101;">非典型病例,请做进一步检查</span>
        <span style="color: #ff0101;">{{xtfx}}</span>
        <span class="qrjl" @click="qrjl">确认结论</span>
      </div>
    </div>
    <div class="pgxz">
      <p>*评估结论</p>
      <div class="xz-box">
        <div v-for="(item, idx) in list"><img @click="dele(item, idx)" src="@/assets/img/fx.png" alt=""><p>{{item}}</p></div>
      </div>
      <el-select
          class="xz"
          v-model="value2"
          @change="bh"
          multiple
          collapse-tags
          placeholder="自定义评估结果">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import conclusion from "@/publicFn/ViewFn"
export default {
  name: "ViewFnAssess",
  data() {
    return {
      options: [
        {
          value: '调节不足',
          label: '调节不足'
        },
        {
          value: '调节过度',
          label: '调节过度'
        },
        {
          value: '调节灵活度不良',
          label: '调节灵活度不良'
        },
        {
          value: '调节不能持久',
          label: '调节不能持久'
        },
        {
          value: '单纯性内隐斜',
          label: '单纯性内隐斜'
        },
        {
          value: '单纯性外隐斜',
          label: '单纯性外隐斜'
        },
        {
          value: '集合不足',
          label: '集合不足'
        },
        {
          value: '集合过度',
          label: '集合过度'
        },
        {
          value: '假性集合不足',
          label: '假性集合不足'
        },
        {
          value: '散开不足',
          label: '散开不足'
        },
        {
          value: '散开过度',
          label: '散开过度'
        },
        {
          value: '融像性聚散减低',
          label: '融像性聚散减低'
        },
        {
          value: '垂直位双眼平衡失调',
          label: '垂直位双眼平衡失调'
        },
        {
          value: '非典型病例(待定)',
          label: '非典型病例(待定)'
        }
      ],
      value2: [],
      list: [],
      setlist: new Set(),
      postData: {},
      ampNum: '',
      xtfx: '',
      xtfxarr: []
    }
  },

  watch: {
    list: {
      handler() {
        this.postData.S_OP_Json.S_OP_VF_Diagnosis = this.list.join(',')
        this.$store.commit('eyeTest/upViewFnTest', this.postData)
      },
      deep: true
    }
  },

  //ESO = BI,  EXO=BO
  computed: {
    Ybo() {
      let tj1 = this.postData.S_OP_Json.S_OP_VF_HP_Far && this.postData.S_OP_Json.S_OP_VF_HP_Far >= 0,
          tj2 = this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint !== 'X' && this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint >= 0,
          tj3 = this.postData.S_OP_Json.S_OP_VF_BIBOFar !== ''
      if (tj1 && tj2 && tj3) {
        let num = this.postData.S_OP_Json.S_OP_VF_BIBOFar=="BI"?-1:1,
            rest = this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint - this.postData.S_OP_Json.S_OP_VF_HP_Far*num
        if (rest >= 9) {
          return "正常"
        }else {
          return "低"
        }
      }
      return "--"
    },
    Ybi() {
      let tj1 = this.postData.S_OP_Json.S_OP_VF_HP_Far && this.postData.S_OP_Json.S_OP_VF_HP_Far >= 0,
          tj2 = this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint && this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint >= 0,
          tj3 = this.postData.S_OP_Json.S_OP_VF_BIBOFar !== ''
      if (tj1 && tj2 && tj3) {
        let num = this.postData.S_OP_Json.S_OP_VF_BIBOFar=="BI"?-1:1,
            rest = this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint - this.postData.S_OP_Json.S_OP_VF_HP_Far*num
        if (rest >= 9) {
          return "正常"
        }else {
          return "低"
        }
      }
      return "--"
    },

    Jbo() {
      let tj1 = this.postData.S_OP_Json.S_OP_VF_HP_Near && this.postData.S_OP_Json.S_OP_VF_HP_Near >= 0,
          tj2 = this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint !== 'X' && this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint >= 0,
          tj3 = this.postData.S_OP_Json.S_OP_VF_BIBONear !== ""
      if (tj1 && tj2 && tj3) {
        let num = this.postData.S_OP_Json.S_OP_VF_BIBONear == "BO"?1:-1,
            rest = this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint + num*this.postData.S_OP_Json.S_OP_VF_HP_Near
        if (rest >= 21) {
          return "正常"
        }else {
          return "低"
        }
      }
      return "--"
    },
    Jbi() {
      let tj1 = this.postData.S_OP_Json.S_OP_VF_HP_Near && this.postData.S_OP_Json.S_OP_VF_HP_Near >= 0,
          tj2 = this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint !== 'X' && this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint >= 0,
          tj3 = this.postData.S_OP_Json.S_OP_VF_BIBONear !== ""
      if (tj1 && tj2 && tj3) {
        let num = this.postData.S_OP_Json.S_OP_VF_BIBONear == "BO"?1:-1,
            rest = this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint + num*this.postData.S_OP_Json.S_OP_VF_HP_Near
        if (rest >= 21) {
          return "正常"
        }else {
          return "低"
        }
      }
      return "--"
    },

    Yzxd() {
      if (this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint !== '' && this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint != 'X' ) {
        if (this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint < 5) {
          return "低"
        }else if (this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint > 13) {
          return "高"
        }else if (this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint > 5 && this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint < 13) {
          return "正常"
        }
      }
      return "--"
    },
    Yfxd() {
      if (this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint !== '') {
        if (this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint < 4) {
          return "低"
        }else if (this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint > 10) {
          return "高"
        }else if (this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint > 4 && this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint < 10) {
          return "正常"
        }
      }
      return "--"
    },

    Jzxd() {
      if (this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint !== '' && this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint != 'X' ) {
        if (this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint < 12) {
          return "低"
        }else if (this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint > 22) {
          return "高"
        }else if (this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint > 12 && this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint < 22) {
          return "正常"
        }
      }
      return "--"
    },
    Jfxd() {
      if (this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint !== '' && this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint != 'X' ) {
        if (this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint < 9) {
          return "低"
        }else if (this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint > 17) {
          return "高"
        }else if (this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint > 9 && this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint < 17) {
          return "正常"
        }
      }
      return "--"
    }
  },

  created() {
    if (this.$store.state.users.CsAge) {
      this.ampNum = 13-0.25*this.$store.state.users.CsAge
    }

    let datas = this.$store.state.eyeTest.ViewFnTest
    this.postData = this.$store.state.eyeTest.ViewFnTest
    if (datas && datas.S_OP_Json) {
      let arr = Array.from(conclusion(datas.S_OP_Json, this.$store.state.users.CsAge))
      if (arr.length) {
        this.xtfxarr = arr
        this.xtfx = arr.join("，")
      }
      if (datas.S_OP_Json.S_OP_VF_Diagnosis) {
        this.list = datas.S_OP_Json.S_OP_VF_Diagnosis.split(',')
        for (let i = 0; i < this.list.length; i++) {
          if (!this.list[i]) {
            this.list.splice(i,1)
          }
          if (this.list[i]) {
            this.setlist.add(this.list[i])
          }
        }
      }
    }
  },
  methods: {
    bh(n) {
      this.setlist.add(n[0])
      this.list = Array.from(this.setlist.values())
      this.value2 = []
    },
    dele(item, idx) {
      this.setlist.delete(item)
      this.list.splice(idx, 1)
    },
    qrjl() {
      this.list = [...this.xtfxarr]
    }
  }
}
</script>

<style scoped lang="scss">
.ViewFnAssess {width: 60vw; text-align: left;}
.zb {
  p {
    margin: 12px 0;
    padding-left: .2rem;
    width: 18vw;
  }
  .zb-box {
    display: flex;
    align-items: center;
  }
  .zb-right {padding-left: 2rem}
}
.pgxz {
  display: flex;
  align-items: start;
  .xz {
    width: 150px;
  }
  .xz-box {
    width: 40vw;
    display: flex;
    flex-wrap: wrap;
    div {
      display: flex;
      align-items: center;
      padding: 0 1vw;
      margin-bottom: 10px;
      p {
        line-height: 40px;
        color: #fa0404;
      }
    }
    img {
      display: block;
      width: 40px;
    }
  }
}
.cwcolor {color: #ff0101}
.qrjl {
  border: 1px solid #8f8e8e;
  padding: 3px;
  border-radius: 3px;
  margin-left: 10px;
}
</style>
