let Maps = new Map()

//1是符合，2是不符合，3是缺少参数不做判断
//远是否满足sheard准则
function isYSheard(obj) {
    let sp = obj.S_OP_VF_HP_Far
    if (sp === "") {
        return 3
    }
    if (obj.S_OP_VF_BIBOFar == 'BO') {
        let pl = obj.S_OP_VF_BIFarBDPoint
        if (pl === "") return 3
        if (sp*2 > pl) {
            return 2
        }else if (sp*2 <= pl) {
            return 1
        }
    }else if (obj.S_OP_VF_BIBOFar == 'BO') {
        let pl = this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint
        if (pl === '') return 3
        if (sp*2 > pl) {
            return 2
        }else if (sp*2 <= pl || pl == 'X') {
            return 1
        }
    }
    return 3
}

//远是否满足1:1准则
function isY1b1(obj) {
    let sp = obj.S_OP_VF_HP_Far
    if (obj.S_OP_VF_BIBOFar == "BO") {
        let hf = obj.S_OP_VF_BIFarRecPoint
        if (hf === '') return 3
        if (hf < sp) {
            return 2
        }else if (hf >= sp) {
            return 1
        }
    }else if (obj.S_OP_VF_BIBOFar == "BI") {
        return 2
    }
    return 3
}

//远是否满足percival准则
function isYpercival(obj) {
    let bo =  obj.S_OP_VF_BOFarBlurPoint,
        bi = obj.S_OP_VF_BIFarBDPoint,
        arr = ''
    // jsxac = this.postData.S_OP_Json.S_OP_VF_ACA

    if (bo === '' && bi === '') return 3


    if (bo == "X") {
        return 1
    }

    if (bo > bi) {
        if (bi > bo/2) {
            return 1
        }else if (bi < bo/2){
            return 2
        }
    }

    if (bi > bo) {
        if (bo > bi/2) {
            return 1
        }else if (bo < bi/2) {
            return 2
        }
    }

    return 3
}

//近是否满足sheard准则
function isJsheard(obj) {
    let sp = obj.S_OP_VF_HP_Near
    if (obj.S_OP_VF_BIBONear == "BO") {
        let pl = obj.S_OP_VF_BINearBDPoint
        if (pl === '') return 3
        if ( sp*2 > pl) {
            return 2
        }else if (sp*2 <= pl) {
            return 1
        }
    }else if (obj.S_OP_VF_BIBONear == "BI") {
        let pl = obj.S_OP_VF_BONearBlurPoint
        if (pl === '') return 3
        if ( sp*2 > pl) {
            return 2
        }else if (sp*2 <= pl || pl == 'X') {
            return 1
        }
    }
    return 3
}

//近是否满足1:1准则
function isJ1b1(obj) {
    let sp = obj.S_OP_VF_HP_Near
    if (obj.S_OP_VF_BIBONear == "BO") {
        let hf = obj.S_OP_VF_BINearRecPoint
        if (hf === '') return 3
        if (hf < sp) {
            return 2
        }else if (hf >= sp) {
            return 1
        }
    }else if (obj.S_OP_VF_BIBONear == "BI") {
        return 2
    }
    return 3
}

//近是否满足percival准则
function isJpercival(obj) {
    let bo =  obj.S_OP_VF_BONearBlurPoint,
        bi = obj.S_OP_VF_BINearBlurPoint,
        // bip = this.postData.S_OP_Json.S_OP_VF_BINearBDPoint
        arr = ''
    if (bo === '' && bi === '') return 3

    bo = bo == 'X'?0:bo
    bi = bi == 'X'?0:bi
    if (bo == "X" && bi == "X") {
        return 1
    }

    if (bo > bi) {
        if (bi > bo/2) {
            return 1
        }else if (bi < bo/2){
            return 2
        }
    }

    if (bi > bo) {
        if (bo > bi/2) {
            return 1
        }else if (bo < bi/2) {
            return 2
        }
    }

    return 3
}



//判断调节不足
function tjbz(obj, age) {
    let num = obj.S_OP_VF_Flipper_OU.match(/\d+/g)?obj.S_OP_VF_Flipper_OU.match(/\d+/g)[0]:''
    let tj1 = obj.S_OP_VF_AMP_OD1 < 15 - age*0.25 && obj.S_OP_VF_AMP_OS1 < 15 - age*0.25,
        tj2 = obj.S_OP_VF_Flipper_OU < num && obj.S_OP_VF_Flipper_OU.indexOf("-")>-1,
        tj3 = obj.S_OP_VF_NRA_OU < 2.5 && obj.S_OP_VF_NRA_OU > 2,
        tj4 = obj.S_OP_VF_PRA_OU < -1.5
    if (tj1 && tj2 && tj3 && tj4) {
        Maps.set("调节不足", '调节不足')
    }
}

//调节过度
function tjgd(obj, age) {
    let lhodnum = obj.S_OP_VF_Flipper_OD.match(/\d+/g)?obj.S_OP_VF_Flipper_OD.match(/\d+/g)[0]:'',
        lhosnum = obj.S_OP_VF_Flipper_OS.match(/\d+/g)?obj.S_OP_VF_Flipper_OS.match(/\d+/g)[0]:''
    let fd0d = (obj.S_OP_VF_AMP_OD1 == (15-0.25*age)+2) || (obj.S_OP_VF_AMP_OD1 == (15-0.25*age)-2) || (obj.S_OP_VF_AMP_OD1 > 25-0.4*age),
        fdos = (obj.S_OP_VF_AMP_OS1 == (15-0.25*age)+2) || (obj.S_OP_VF_AMP_OS1 == (15-0.25*age)-2) || (obj.S_OP_VF_AMP_OS1 > 25-0.4*age)
    let tj1 = obj.S_OP_VF_NRA_OU <= 2,
        tj2 = (lhodnum < 12 && obj.S_OP_VF_Flipper_OD.indexOf("+")>-1) || (lhosnum < 12 && obj.S_OP_VF_Flipper_OS.indexOf("+")>-1),
        tj3 = fd0d && fdos
    if (tj1 && tj2 && tj3) {
        Maps.set("调节过度", '调节过度')
    }

    if (obj.S_OP_VF_BCC_OU < 0 || obj.S_OP_VF_BCC_OU === 0) {
        Maps.set("调节过度", '调节过度')
    }
}

//调节灵活度不良
function tjlhdbl(obj) {
    let lhodnum = obj.S_OP_VF_Flipper_OD.match(/\d+/g)?obj.S_OP_VF_Flipper_OD.match(/\d+/g)[0]:'',
        lhosnum = obj.S_OP_VF_Flipper_OS.match(/\d+/g)?obj.S_OP_VF_Flipper_OS.match(/\d+/g)[0]:''

    let tj1 = (lhodnum < 12 && obj.S_OP_VF_Flipper_OD.indexOf("±") > -1) || (lhosnum < 12 && obj.S_OP_VF_Flipper_OS.indexOf("±") > -1),
        tj2 = obj.S_OP_VF_NRA_OU < 2,
        tj3 = obj.S_OP_VF_PRA_OU < -1.5

    if (tj1 && tj2 && tj3) {
        Maps.set("调节灵活度不良", '调节灵活度不良')
    }
}

//单纯性内隐斜
function dcxnyx(obj) {
    let tj1 = obj.S_OP_VF_BIBOFar == "BO" && obj.S_OP_VF_BIBONear == 'BO',
        tj2 = obj.S_OP_VF_HP_Far >= 3 || obj.S_OP_VF_HP_Near >= 3,
        tj3 = Math.abs(obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near) < 4,
        tj4 = obj.S_OP_VF_ACAStep < 5 && obj.S_OP_VF_ACAStep > 3,
        tj5 = obj.S_OP_VF_PRA_OU < -1.5
    if (isYSheard(obj) == 2 || isJsheard(obj) == 2) {
        if (tj1 && tj2 && tj3 && tj4 && tj5) {
            Maps.set("单纯性内隐斜", "单纯性内隐斜")
        }
    }


    let towtj1 = obj.S_OP_VF_HP_Far >= 6 || obj.S_OP_VF_HP_Near >= 6

    if (tj1 && towtj1 && tj3 && tj4) {
        Maps.set("单纯性内隐斜", "单纯性内隐斜")
    }
}

//单纯性外隐斜
function dcxwyx(obj) {
    let tj1 = obj.S_OP_VF_BIBONear == "BI" && obj.S_OP_VF_BIBOFar == 'BI',
        tj2 = obj.S_OP_VF_HP_Far >= 5 || obj.S_OP_VF_HP_Near >= 5,
        tj3 = Math.abs(obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near) < 4,
        tj4 = obj.S_OP_VF_ACAStep < 5 && obj.S_OP_VF_ACAStep > 3,
        tj6 = obj.S_OP_VF_NRA_OU < 2
    if (isYSheard(obj) == 2 || isJsheard(obj) == 2) {
        if (tj1 && tj2 && tj3 && tj4 && tj6) {
            Maps.set("单纯性外隐斜", "单纯性外隐斜")
        }
    }

    let towtj1 = obj.S_OP_VF_HP_Far >= 8 || obj.S_OP_VF_HP_Near >= 8

    if (tj1 && towtj1 && tj3 && tj4) {
        Maps.set("单纯性外隐斜", "单纯性外隐斜")
    }
}

//集合不足
function jhbz(obj) {
    let num = obj.S_OP_VF_Flipper_OU.match(/\d+/g)?obj.S_OP_VF_Flipper_OU.match(/\d+/g)[0]:''
    let tj1 = obj.S_OP_VF_BIBONear == 'BI' && obj.S_OP_VF_BIBOFar == "BO",
        tj2 = obj.S_OP_VF_HP_Far > 3 && obj.S_OP_VF_HP_Near > 3,
        tj3 = obj.S_OP_VF_HP_Near >= 4,
        tj4 = obj.S_OP_VF_NPC1 >= 10,
        tj5 = obj.S_OP_VF_HP_Near - obj.S_OP_VF_HP_Far >= 4,
        tj6 = obj.S_OP_VF_ACAStep < 3,
        tj7 = num < 8 && obj.S_OP_VF_Flipper_OU.indexOf("+") > -1,
        tj8 = obj.S_OP_VF_NRA_OU <= 2,
        tj9 = obj.S_OP_VF_BCC_OU <= 0,
        tj10 = obj.S_OP_VF_HP_Near - obj.S_OP_VF_HP_Far >= 8
    if (tj1 && tj2 && tj3 && tj4) {
        Maps.set("集合不足", "集合不足")
    }//1

    if (isJsheard(obj) == 2) {
        if (tj1 && tj5) {
            Maps.set("集合不足", "集合不足")
        }
    }//2

    if (tj1 && tj5 && tj6) {
        Maps.set("集合不足", "集合不足")
    }//3

    if (tj1 && tj7 && tj5) {
        Maps.set("集合不足", "集合不足")
    }//4

    if (tj1 && tj5 && tj8) {
        Maps.set("集合不足", "集合不足")
    }//5

    if (tj1 && tj9 && tj5) {
        Maps.set("集合不足", "集合不足")
    }//6

    if (tj1 && tj10) {
        Maps.set("集合不足", "集合不足")
    }//7
}

//集合过度
function jhgd(obj) {
    let num = obj.S_OP_VF_Flipper_OU.match(/\d+/g)?obj.S_OP_VF_Flipper_OU.match(/\d+/g)[0]:''
    let tj1 = obj.S_OP_VF_BIBONear == 'BO' && obj.S_OP_VF_BIBOFar == "BI",
        tj2 = obj.S_OP_VF_HP_Far <= 3 && obj.S_OP_VF_HP_Near <= 3,
        tj3 = obj.S_OP_VF_HP_Near - obj.S_OP_VF_HP_Far >= 4,
        tj4 = obj.S_OP_VF_PRA_OU > -2 && obj.S_OP_VF_PRA_OU < 0,
        tj5 = num < 8 && obj.S_OP_VF_Flipper_OU.indexOf("-") > -1,
        tj6 = obj.S_OP_VF_BCC_OU >= 1,
        tj7 = obj.S_OP_VF_ACA > 5
    if (tj1) {
        if (isJ1b1(obj) == 2 || isJpercival(obj) == 2) {
            if (tj2) {
                Maps.set('集合过度', '集合过度')
            }
        }
        if (tj3) {
            if (tj4) {
                Maps.set('集合过度', '集合过度')
            }
            if (tj5) {
                Maps.set('集合过度', '集合过度')
            }
            if (tj6) {
                Maps.set('集合过度', '集合过度')
            }
            if (tj7) {
                Maps.set('集合过度', '集合过度')
            }
        }
    }
}

//散开过度
function skgd(obj) {
    let tj1 = obj.S_OP_VF_BIBONear == 'BI' && obj.S_OP_VF_BIBOFar == "BO",
        tj2 = obj.S_OP_VF_ACA <= 3,
        tj3 = obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near >= 4,
        tj4 = obj.S_OP_VF_ACAStep > 5,
        tj5 = obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near > 6
    if (isYSheard(obj) == 2) {
        if (tj1 && tj3) {
            Maps.set("散开过度",'散开过度')
        }
        if (tj1 && tj4) {
            Maps.set("散开过度",'散开过度')
        }
    }
    if (tj1 && tj5) {
        Maps.set("散开过度",'散开过度')
    }

}

//散开不足
function skbz(obj) {
    let tj1 = obj.S_OP_VF_BIBONear == 'BI' && obj.S_OP_VF_BIBOFar == "BO",
        tj2 = obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near >= 4,
        tj3 = obj.S_OP_VF_ACA < 3,
        tj4 = obj.S_OP_VF_HP_Far - obj.S_OP_VF_HP_Near >= 6
    if (isY1b1(obj) == 2) {
        if (tj1 && tj2) {
            Maps.set("散开不足", "散开不足")
        }
        if (tj1 && tj3) {
            Maps.set("散开不足", "散开不足")
        }
    }

    if (tj1 && tj4) {
        Maps.set("散开不足", "散开不足")
    }
}

//融像性聚散减低
function rxjd(obj) {
    let ybip = obj.S_OP_VF_BIFarBDPoint < 5,
        ybom = obj.S_OP_VF_BOFarBlurPoint < 7,
        ybop = obj.S_OP_VF_BOFarBDPoint < 15,
        jbim = obj.S_OP_VF_BINearBlurPoint != 'X' && obj.S_OP_VF_BINearBlurPoint < 11,
        jbip = obj.S_OP_VF_BINearBDPoint < 15,
        jbom = obj.S_OP_VF_BONearBlurPoint != 'X' && obj.S_OP_VF_BONearBlurPoint < 14,
        jbop = obj.S_OP_VF_BONearBDPoint < 18
    let tjybi = false, tjybo = false, tjjbi = false, tjjbo = false

    // if (ybip) {tjybi = true}
    if (obj.S_OP_VF_BOFarBlurPoint != '') {
        if (ybom) {tjybo = true}
    }else {
        if (obj.S_OP_VF_BOFarBDPoint != '' && ybop) {
            tjybo = true
        }
    }

    if (obj.S_OP_VF_BINearBlurPoint != '') {
        if (jbim) {tjjbi = true}
    }else {
        if (obj.S_OP_VF_BINearBDPoint != '' && jbip) {
            tjjbi = true
        }
    }

    if (obj.S_OP_VF_BONearBlurPoint != '') {
        if (jbom) tjjbo = true
    }else {
        if (obj.S_OP_VF_BONearBDPoint != "" && jbop) tjjbo = true
    }

    if (tjybo && tjjbi && tjjbo) {
        Maps.set("融像性聚散减低", "融像性聚散减低")
    }
}

//垂直位双眼平衡失调
function czst(obj) {
    if (obj.S_OP_VF_VP_Far !== '') {
        if (Number(obj.S_OP_VF_VP_Far) == 0) {
            Maps.set("垂直位双眼平衡失调", "垂直位双眼平衡失调")
        }
    }

    if (obj.S_OP_VF_VP_Near !== '') {
        if (Number(obj.S_OP_VF_VP_Near) == 0) {
            Maps.set("垂直位双眼平衡失调", "垂直位双眼平衡失调")
        }
    }
}

function conclusion(obj, age) {
    Maps = new Map()

    if (!obj) return []

    obj = JSON.parse(JSON.stringify(obj))

    tjbz(obj, age)
    tjgd(obj, age)
    tjlhdbl(obj)
    dcxnyx(obj)
    dcxwyx(obj)
    jhbz(obj)
    jhgd(obj)
    skgd(obj)
    skbz(obj)
    czst(obj)
    rxjd(obj)


    return Maps.values()
}



export default conclusion

